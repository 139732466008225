
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, InternalUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class HostessLogin extends Vue {

    languageCredential: OM.LanguageCredential = new OM.LanguageCredential();

    created() {
    }

    login() {
        InternalUserClient.login(this.languageCredential)
        .then(x => {
            LoginServices.LoginCallback(x.token, x.refreshToken, this.languageCredential.preferredCulture);
        })
        .catch(y => {
            // this.$opModal.show(InfoModal, {
            //     text:this.$localizationService.getLocalizedValue("backoffice_Errore", "Errore"),
            //     subtitle: y.Message,
            //     deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            //     denyCb: () => {
            //         this.$opModal.closeLast();
            //     }
            // })
        })
    }

    get disabled() {
        return !this.languageCredential.username || !this.languageCredential.password;
    }

}
